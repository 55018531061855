import React, { useState } from "react";
import { create } from "zustand";
import DataListStore from "../../../store/DataListStore";
import ListViewV2 from "../../App/DataListV2";
import { ProForm, ProFormDigit } from "@ant-design/pro-components";
import { AddNewCoupons } from "services/datalist/datalist-api";
import { notification } from "antd";

const useCouponDataStore = create(DataListStore);

const Coupon = () => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <h3>Add New Coupons</h3>
      <ProForm
        disabled={loading}
        onFinish={async (val) => {
          setLoading(true);
          const res = await AddNewCoupons(val.numOfCoupon);
          notification.info({message: "Add New Coupon", description: res})
          setReload(!reload)
          setLoading(false);

        }}
      >
        <ProFormDigit
          width={"md"}
          name="numOfCoupon"
          label="Number of Coupons"
          rules={[{required: true, message:"Masukkan jumlah kupon yang ingin di buat."}]}
        ></ProFormDigit>
        <br />
      </ProForm>
      <br />
      <br />
      <ListViewV2 viewId="coupons" store={useCouponDataStore} reloader={reload}/>
    </>
  );
};

export default Coupon;
