import {
  Alert,
  Button,
  Card,
  Form,
  message,
  notification,
  Progress,
  Result,
  Space,
  Upload,
} from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { InboxOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import {
  getRequest,
  postFile,
  requestPageComment,
} from "../../services/EndPointApiService"
import { downloadTemplate } from "../../services/datalist/datalist-api"
import {
  ProForm,
  ProFormField,
  ProFormText,
  ProTable,
  Search,
} from "@ant-design/pro-components"
import { Container } from "reactstrap"

const Uploader = () => {
  const params = useParams()
  const [loading, setLoading] = useState(false)

  const [resultFile, setResultFile] = useState([])
  const [allowUpload, setAllowUpload] = useState(false)
  const [pendingFormData, setPendingFormData] = useState(null)

  const [proccessFinish, setProccessFinish] = useState(false)
  const [proccessResult, setProccessResult] = useState(null)

  const initPage = () => {
    setResultFile([])
    setAllowUpload(false)
    setProccessFinish(false)
    setPendingFormData(null)
    setProccessResult(null)
  }

  useEffect(() => {
    initPage()
  }, [params])

  return (
    <>
      <Container>
        {params.type.indexOf("comment") > -1 && (
          <Card>
            <ProForm
              grid
              disabled={loading}
              submitter={{
                submitButtonProps: {
                  loading: loading,
                  title: "Fetch Data",
                  size: "large",
                },
                resetButtonProps: false,
              }}
              onFinish={async (val) => {
                setLoading(true)
                const url = "upload/GetDataComments/" + val.pageId

                let response = await getRequest(url, {
                  observe: "response",
                  responseType: "blob",
                })

                let fileName = 'comments-'+val.pageId+".xlsx"
                const a = document.createElement("a");
                a.setAttribute("style", "display:none;");
                document.body.appendChild(a);
                const file = new Blob([response]);
                a.download = fileName;
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(file, fileName);
                } else {
                  a.href = URL.createObjectURL(file);
                }
                a.target = "_blank";
                a.click();
                document.body.removeChild(a);

                // setProccessResult(response)
                // setProccessFinish(true)
                setLoading(false)
              }}
            >
              <ProFormField>
                <div style={{ fontSize: 18 }}>
                  <b>Download Comment From Live</b>
                </div>
                <hr />
              </ProFormField>
              <ProFormText
                colProps={{ md: 12 }}
                fieldProps={{
                  size: "large",
                }}
                name={"pageId"}
                label="Page Id"
                rules={[
                  { required: true, message: "Please enter Page ID." },
                  {
                    pattern: "^[0-9]+$",
                    message:
                      "Page ID value are numeric, Please enter valid Page ID.",
                  },
                ]}
              />
            </ProForm>
          </Card>
        )}
        <br />
        <Card>
          <Form>
            {params.type === "resi" && (
              <>
                <Button
                  size="large"
                  type="primary"
                  onClick={async () => {
                    return downloadTemplate("TEMPLATE_RESI.xlsx")
                  }}
                >
                  <Space>
                    <DownloadOutlined />
                    Download Template Resi
                  </Space>
                </Button>
                <br />
                <br />
              </>
            )}

            <Upload
              multiple={false}
              name="file"
              type="drag"
              showUploadList={false}
              disabled={loading}
              beforeUpload={async (file) => {
                initPage()
                setLoading(true)
                message.warning("Uploading your file please wait")
                let postUrl = "upload/Dynamic"
                if (params.type === "commentnew") postUrl = postUrl + "/pipe"
                let formData = new FormData()
                formData.append("file", file)
                const result = await postFile(postUrl, formData)
                const resultData = result.data
                setResultFile(resultData)
                setPendingFormData(formData)
                setAllowUpload(resultData?.data?.length > 0)
                message.success("File Uploaded")
                setLoading(false)
                return false
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Upload {params.type}</p>
              <p className="ant-upload-hint">
                Click or drag your file here. Please provide valid file before
                uploading
              </p>
              <Button disabled={loading} loading={loading} size="large">
                Upload {params.type}
              </Button>
            </Upload>
            <br />
            <br />

            {allowUpload && !proccessFinish && (
              <>
                {loading && (
                  <Progress
                    showInfo={false}
                    percent={99.9}
                    status="active"
                    strokeColor={{ from: "#108ee9", to: "#87d068" }}
                  />
                )}
                <Alert
                  message={
                    loading
                      ? "Please wait your upload in progress..."
                      : "Your Files Ready to Process"
                  }
                  type={loading ? "warning" : "info"}
                  showIcon
                />
                <ProTable
                  headerTitle={
                    <div style={{ color: "#FF1212" }}>
                      Always check file before process
                    </div>
                  }
                  scroll={{
                    x: "max-content",
                  }}
                  cardProps={false}
                  search={false}
                  toolbar={{
                    search: false,
                    settings: false,
                    actions: !allowUpload
                      ? []
                      : [
                          <Button
                            size="large"
                            key={"uploadFile"}
                            type="primary"
                            loading={loading}
                            onClick={async () => {
                              message.warning(
                                "Uploading your data. Please wait..."
                              )
                              setLoading(true)
                              const result = await postFile(
                                "upload/" + params.type,
                                pendingFormData
                              )
                              const resultData = result.data
                              if (resultData.status === "Success") {
                                notification.success({
                                  message: "Upload Data",
                                  description: resultData.message,
                                })
                              } else {
                                notification.error({
                                  message: "Upload Data",
                                  description: resultData.message,
                                })
                              }
                              setProccessResult(resultData)
                              setProccessFinish(true)
                              setLoading(false)
                            }}
                          >
                            Process Upload
                          </Button>,
                        ],
                  }}
                  columns={resultFile.columns.map((c) => ({
                    key: c,
                    title: c,
                    dataIndex: c,
                  }))}
                  dataSource={resultFile.data.map((d, i) => ({ ...d, key: i }))}
                />
              </>
            )}

            {proccessFinish && (
              <Result
                status={
                  proccessResult.status === "Success" ? "success" : "error"
                }
                title={"Upload Process " + proccessResult.status}
                subTitle={proccessResult.message}
              />
            )}
          </Form>
        </Card>
      </Container>
    </>
  )
}

export default Uploader
